const teams = [
  { id: 1, title: '그외' },
  { id: 2, title: '개발' },
  { id: 3, title: '연구' },
];

const people = [
  { id: 1, name: '이승명', team: 1 },
  { id: 2, name: '박준홍', team: 1 },
  { id: 3, name: '안용대', team: 1 },
  { id: 4, name: '정승우', team: 2 },
  { id: 5, name: '문정호', team: 3 },
  { id: 6, name: '박채은', team: 1 },
  { id: 10, name: '조민주', team: 1 },
  { id: 13, name: '조은갑', team: 2 },
  { id: 14, name: '정대산', team: 2 },
  { id: 15, name: '안경록', team: 2 },
  { id: 16, name: '김주환', team: 3 },
  { id: 17, name: '류한열', team: 3 },
  { id: 18, name: '김원석', team: 3, mia: true },
  { id: 19, name: '양철순', team: 2 },
  { id: 20, name: '정희원', team: 3 },
  { id: 22, name: '양은모', team: 2 },
  { id: 23, name: '유동훈', team: 3 },
  { id: 24, name: '남궁현', team: 2 },
  { id: 25, name: '손민우', team: 1 },
  { id: 26, name: '김도영', team: 2 },
  { id: 29, name: '김동규', team: 1 },
  { id: 30, name: '백지원', team: 1 },
  { id: 31, name: '김휘년', team: 2 },
  { id: 32, name: '쟈니백', team: 3 },
  { id: 33, name: '신희재', team: 2 },
  { id: 34, name: '이혁기', team: 3 },
  { id: 35, name: '이상륜', team: 3, mia: true },
  { id: 36, name: '박다련', team: 1 },
  { id: 37, name: '이주형', team: 2 },
  { id: 38, name: '오용우', team: 3 },
  { id: 39, name: '김효준', team: 3 },
  { id: 40, name: '박규연', team: 3 },
  { id: 41, name: '이치상', team: 2 },
  { id: 42, name: '이혜진', team: 1 },
  { id: 43, name: '최유경', team: 1 },
  { id: 44, name: '김유진', team: 1 },
  { id: 45, name: '박지윤', team: 1 },
  { id: 46, name: '이용우', team: 3 },
];

export { teams, people };
